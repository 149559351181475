<template>
    <div v-cloak>
        <div v-if="authenticated" class="min-h-screen grid grid-cols-2">
            <div class="col-span-1 p-5">
                <!-- {{lines}} -->
                <div v-if="transaction">
                    <div v-if="transaction.lines.length > 0" class="border border-b-0 p-5 h-80vh transaction-content overflow-auto flex flex-col-reverse">
                        <div class="transaction-line font-bold grid  grid-cols-12 py-1 px-1 flex items-start" v-for="(line, index) in transaction.lines.slice().reverse()" :key="index">
                            <div class="col-span-2 flex justify-end">
                                <div class="text-lg pr-5 leading-5">{{ line.quantity }}x</div>
                            </div>
                            <div class="col-span-8 text-left">
                                <div class="text-lg leading-5 truncate" :class="{'ml-3 italic': line.addon}">{{ line.description }}</div>
                                <div class="italic text-xs" v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries" v-bind:key="grocery_index">
                                    {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                                </div>
                            </div>

                            <div class="text-lg col-span-2 flex items-center justify-end">
                                <div>
                                    <div>{{ vueNumberFormat(line.total) }}</div>
                                    <div class="text-xs text-gray-300" v-if="line.discount_amount > 0">(-{{ vueNumberFormat(line.discount_amount) }})</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- total -->
                    <div v-if="transaction.lines.length > 0"  id="total" class="p-5 bg-shadow border border-t-0">
                        <div class="flex items-center justify-between font-bold text-lg">
                            <div class="text-right text-base">{{$t('translations.views.pos.display.customer-display.number-of')}}: {{ transaction.items }}</div>
                            <div class="flex justify-between">
                                <span class="mr-4">{{$t('translations.views.pos.display.customer-display.total')}}:</span>
                                <span><currency/>{{ vueNumberFormat(transaction.total ? transaction.total : 0) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-1 p-5 flex justify-center items-center">
                <img class="mb-3 w-3/4" :src="terminal.customerdisplay_image_url" />
                <!-- <video autoplay loop muted class="w-full h-80vh">
                    <source src="https://assets.mixkit.co/videos/preview/mixkit-mug-with-sparkling-cappuccino-5072-large.mp4" type="video/mp4" />Your browser does not support the video tag.
                </video> -->
            </div>
        </div>
        <div v-else class="min-h-screen bg-gray-900 flex items-center justify-center">
            <h1 class="text-white text-6xl">{{$t('translations.views.pos.display.customer-display.cash-register-closed')}}</h1>
        </div>
    </div>

</template>

<script>
export default {
    name: 'CustomerDisplay',
    components: {
    },

    data() {
        return {
            authenticated: false,
            authenticatedInterval: null
        }
    },
    created() {
        this.authenticated = this.$store.getters['auth/authenticated'];

        this.authenticatedInterval = setInterval(function() {
            this.isAuthenticated();
        }.bind(this), 30000);
    },
    destoyed: function(){
        clearInterval(this.authenticatedInterval)
    },

    methods: {
        isAuthenticated() {
            let authenticated = this.$store.getters['auth/authenticated'];

            if (this.authenticated != authenticated) {
                clearInterval(this.authenticatedInterval)
                location.reload();
            }

            return authenticated
        }
    },
	computed: {
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
		transaction() {
			return this.$store.getters['transaction/customerdisplay'];
        },
    },
}
</script>
